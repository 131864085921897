@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");

body {
  font-family: "Lato", sans-serif;
  margin: 0;
  color: rgb(34, 62, 84);
}

.accordion-wrapper {
  width: 75%;
}

.application-form {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
}

.btn-info {
  background-color: rgb(86, 122, 152);
}

form input,
select {
  /* margin-bottom: 25px; */
  border: none;
  background-color: #eee;
  padding: 9px;
}

textarea {
  margin-bottom: 20px;
  border: none;
  background-color: #eee;
  padding: 9px;
}

.expenses-inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.expenses-inputs input {
  margin: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.form-buttons {
  display: flex;
  justify-content: center;
}

.form-buttons button {
  margin: 3px;
}

.history-text {
  text-align: justify;
  display: flex;
  height: 100%;
  padding: 10px;
}

.table {
  border: 1px solid lightgray;
}

th {
  background-color: lightgray;
}

td {
  height: 25px;
}

td input {
  width: 100%;
  height: 60%;
}

button {
  outline: none;
  border: none;
}

.button {
  padding: 7px;
  background-color: rgb(235, 233, 233);
  margin-top: 2%;
  border-radius: 5px;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.button:hover {
  text-decoration: none;
  background-color: rgb(86, 122, 152);
  color: whitesmoke;
}

.button-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.close-drawer {
  cursor: pointer;
  padding: 25px;
}

.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content {
  padding: 4%;
  min-height: 70vh;
}

.hero-left {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.hero {
  background-color: rgb(86, 122, 152);

  color: whitesmoke;
  /* text-transform: uppercase; */
  letter-spacing: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}
.hero img {
  filter: sepia(0.5);
}

.hero-title {
  border-bottom: 1px solid whitesmoke;
  padding: 20px;
  font-size: 2.3rem;
}

.house-text {
  padding: 25px;
  text-align: justify;
}

.title-bar {
  background-color: rgb(86, 122, 152);
  color: whitesmoke;
  display: flex;
  justify-content: space-between;
}

.title-bar img {
  filter: sepia(0.5);
}

.title-bar h4 {
  font-size: 2rem;
}

.news-article {
  background-color: rgb(197, 221, 241);
  margin-bottom: 2%;
  padding: 2%;
  border-radius: 3px;
}

.trustee {
  width: 30%;
  height: 300px;
  background-color: rgb(221, 240, 240);
  margin: 2%;
  padding: 2%;
  border-radius: 5px;
}

.trustees-wrapper {
  width: 70%;
  margin: auto;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
