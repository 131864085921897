.toolbar{
    position: fixed;
    width: 100%;
    background-color: white;
    height: 56px;
    top: 0;
    left: 0;
    z-index: 200;
    
}

.toolbar__navigation{
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 1rem;
}

.toolbar__logo{
    margin-left: 1rem;
}
.toolbar__logo a{
color: white;
text-decoration: none;
font-size: 1.5rem;
}

.spacer{
    flex: 1;
}
.toolbar_navigation-items ul{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.toolbar_navigation-items li {
    padding: 0 .5rem ;
}

.toolbar_navigation-items a {
    color: rgb(247, 246, 246);
    text-decoration: none;
}

.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active {
    color: pink;
}

