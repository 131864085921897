@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap);
body {
  font-family: "Lato", sans-serif;
  margin: 0;
  color: rgb(34, 62, 84);
}

.accordion-wrapper {
  width: 75%;
}

.application-form {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
}

.btn-info {
  background-color: rgb(86, 122, 152);
}

form input,
select {
  /* margin-bottom: 25px; */
  border: none;
  background-color: #eee;
  padding: 9px;
}

textarea {
  margin-bottom: 20px;
  border: none;
  background-color: #eee;
  padding: 9px;
}

.expenses-inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.expenses-inputs input {
  margin: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.form-buttons {
  display: flex;
  justify-content: center;
}

.form-buttons button {
  margin: 3px;
}

.history-text {
  text-align: justify;
  display: flex;
  height: 100%;
  padding: 10px;
}

.table {
  border: 1px solid lightgray;
}

th {
  background-color: lightgray;
}

td {
  height: 25px;
}

td input {
  width: 100%;
  height: 60%;
}

button {
  outline: none;
  border: none;
}

.button {
  padding: 7px;
  background-color: rgb(235, 233, 233);
  margin-top: 2%;
  border-radius: 5px;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.button:hover {
  text-decoration: none;
  background-color: rgb(86, 122, 152);
  color: whitesmoke;
}

.button-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.close-drawer {
  cursor: pointer;
  padding: 25px;
}

.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content {
  padding: 4%;
  min-height: 70vh;
}

.hero-left {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.hero {
  background-color: rgb(86, 122, 152);

  color: whitesmoke;
  /* text-transform: uppercase; */
  letter-spacing: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}
.hero img {
  -webkit-filter: sepia(0.5);
          filter: sepia(0.5);
}

.hero-title {
  border-bottom: 1px solid whitesmoke;
  padding: 20px;
  font-size: 2.3rem;
}

.house-text {
  padding: 25px;
  text-align: justify;
}

.title-bar {
  background-color: rgb(86, 122, 152);
  color: whitesmoke;
  display: flex;
  justify-content: space-between;
}

.title-bar img {
  -webkit-filter: sepia(0.5);
          filter: sepia(0.5);
}

.title-bar h4 {
  font-size: 2rem;
}

.news-article {
  background-color: rgb(197, 221, 241);
  margin-bottom: 2%;
  padding: 2%;
  border-radius: 3px;
}

.trustee {
  width: 30%;
  height: 300px;
  background-color: rgb(221, 240, 240);
  margin: 2%;
  padding: 2%;
  border-radius: 5px;
}

.trustees-wrapper {
  width: 70%;
  margin: auto;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}



.accordion-title{
    display: flex;
    min-height: 45px;
    background-color: rgb(240, 232, 222);
    padding: 10px;
    margin-top: 5px;
}

.accordion-title:hover{
    cursor: pointer;
    background-color: rgb(218, 206, 192);
}

.accordion-content{
    padding-left: 8px;
    margin-bottom: 15px;
    overflow: hidden;
    transition: max-height 0.6s ease;    
}

.accordion-icon{
    margin-left: auto;
    transition: transform 600ms ease;
}

.rotate{
    transform: rotate(90deg);
}

.active{
    background-color: rgb(226, 224, 224);
}


.toolbar{
    position: fixed;
    width: 100%;
    background-color: white;
    height: 56px;
    top: 0;
    left: 0;
    z-index: 200;
    
}

.toolbar__navigation{
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 1rem;
}

.toolbar__logo{
    margin-left: 1rem;
}
.toolbar__logo a{
color: white;
text-decoration: none;
font-size: 1.5rem;
}

.spacer{
    flex: 1 1;
}
.toolbar_navigation-items ul{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.toolbar_navigation-items li {
    padding: 0 .5rem ;
}

.toolbar_navigation-items a {
    color: rgb(247, 246, 246);
    text-decoration: none;
}

.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active {
    color: pink;
}


.toggle-button {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 24px;
    width: 30px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
    color: rgb(43, 77, 105);

  }
  
  .toggle-button:focus {
    outline: none;
  }
  
  .toggle-button__line {
    width: 30px;
    height: 2px;
    background: rgb(14, 100, 116);
  }
.side-drawer{
    height: 100%;
    background-color: white;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, .5);
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 300px;
    z-index: 200;
    transform: translateX(-100%);
    transition:  300ms ease-out;
}

.side-drawer.open{
    transform: translateX(0);
}

.side-drawer ul{
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    list-style: none;
}

.side-drawer li{
    margin: .5rem .2rem;
}

.side-drawer a{
    color: rgb(34, 62, 84);
    text-decoration: none;

}

.side-drawer a:hover,
.side-drawer a:active{
    color: rgb(14, 50, 100);
}

.header{
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgb(14, 100, 116);

}

.social{
    color: rgb(14, 100, 116);
    display: flex;
    justify-content: space-around;
}
.backdrop{
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0,0,0,.3);
    z-index: 100;
    top: 0;
    left: 0;
}
