

.accordion-title{
    display: flex;
    min-height: 45px;
    background-color: rgb(240, 232, 222);
    padding: 10px;
    margin-top: 5px;
}

.accordion-title:hover{
    cursor: pointer;
    background-color: rgb(218, 206, 192);
}

.accordion-content{
    padding-left: 8px;
    margin-bottom: 15px;
    overflow: hidden;
    transition: max-height 0.6s ease;    
}

.accordion-icon{
    margin-left: auto;
    transition: transform 600ms ease;
}

.rotate{
    transform: rotate(90deg);
}

.active{
    background-color: rgb(226, 224, 224);
}

