.side-drawer{
    height: 100%;
    background-color: white;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, .5);
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 300px;
    z-index: 200;
    transform: translateX(-100%);
    transition:  300ms ease-out;
}

.side-drawer.open{
    transform: translateX(0);
}

.side-drawer ul{
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    list-style: none;
}

.side-drawer li{
    margin: .5rem .2rem;
}

.side-drawer a{
    color: rgb(34, 62, 84);
    text-decoration: none;

}

.side-drawer a:hover,
.side-drawer a:active{
    color: rgb(14, 50, 100);
}

.header{
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgb(14, 100, 116);

}

.social{
    color: rgb(14, 100, 116);
    display: flex;
    justify-content: space-around;
}